@import "../../scss/variables.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: white;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  & .container {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  color: black;
  font-size: 15px;
}

.modal-content {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    margin: 20px 0 10px 0;
    max-width: 800px;
    width: 80%;
    height: auto;
    @media (max-width: 799px) {
      width: 92%;
    }
    @media (max-width: 599px) {
      width: 97%;
    }
  }
  & ul {
    list-style: none;
    margin-block-start: 0;
    padding-inline-start: 0;
  }
}

@media (max-width: 599px) {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  }
}

.modal-body {
  background-color: white;
  border-radius: 10px;
  max-width: 800px;
  margin-top: 100px;
  @media (max-width: 600px) {
    padding: 20px 40px;
  }
}

.portfolio-modal .modal-content h2 {
  margin: 0 35px;
  @media (max-width: 600px) {
    font-size: 35px;
  }
  @media (max-width: 400px) {
    font-size: 30px;
  }
}

.close {
  position: absolute;
  right: 25px;
}


.btn {
  cursor: pointer;
  color: #333;
  outline: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}
