.portfolio-items {
  max-width: 1900px;
  margin: auto;
}

.portfolio-item {
  max-width: 48%;
  padding: 20px;
  box-sizing: border-box;
  flex: 1 0 auto;
  @media(max-width: 800px) {
    max-width: none;
    padding: 0 14px;
    width: 100%;
  }

  & img {
    height: auto;
    width: 100%;
  }
}

