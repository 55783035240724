#about .container {
  flex-direction: column;
}
#about .about-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.2em;
  @media(max-width: 992px) {
    font-size: 1.3em;
    line-height: 30px;
  }
  .about-img {
    max-width: 100%;
    text-align: right;
  }
  line-height: 35px;

  span.clean {
    font-weight: bold
  }

  & .col {
    margin: 0 10px;
  }
}
