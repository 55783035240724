.skills-content {
  margin-top: 1.7em;
  font-size: 1.32em;
  @media(max-width: 992px) {
    font-size: 1.3em;
    margin-top: 0;
  }

  display: flex;
  flex-direction: row;

  & .col {
    flex: 1 0 0;
    margin: 0 10px;
  }
  & .col.img {
    background-image: url("/img/langues.jpg");
    background-size: cover;
  }

  @media (max-width: 1200px) {
    [class*="col-"] {
      float: none;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

#skills {
  & .container {
    flex-direction: column;
    padding: 0 10px;
    box-sizing: border-box;
  }
  div.separator {
    margin-bottom: -5px;
  }

  img {
    max-width: 100%;
  }
}
