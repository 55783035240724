.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 800px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
}
