dl {
  padding: 0;
}

dt {
  position: relative;
  left: 0;
  top: 1.1em;
  width: 5em;
  font-weight: bold;
}

dd {
  border-left: 1px solid #000;
  margin: 0 0 0 6em;
  padding: 0 0 .5em .5em;
}
