@import "variables.scss";
@import "mixins.scss";

body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

p {
  font-size: 20px;
}

p.small {
  font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
}

section {
  & h2 {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
  }
}

.img-centered {
  margin: 0 auto;
}

@media (min-width: 768px) {
  header {
    .container {
      padding-top: 150px;
      padding-bottom: 100px;
    }
    .intro-text {
      .name {
        font-size: 4.75em;
      }
      .skills {
        font-size: 1.75em;
      }
    }
  }
}

.button-outer {
  margin-top: 10px;
  text-align: center;
  a {
    outline: 0;
  }
}

.button {
  cursor: pointer;
  font-size: 16.8px;
  border: 0;
  padding: 13px 20px;
  background-color: #2c3e50;
  color: white;
  border-radius: 10px;
  outline: 0;
}

section,
section {
  padding: 100px 0;
}

header {
  h2 {
    margin: 0;
    font-size: 3em;
  }
}
.header {
  padding: 0;
}

section.success {
  background: $theme-success;
  color: white;
}

@media (max-width: 767px) {
  section {
    padding: 75px 0;
  }
  section.first {
    padding-top: 75px;
  }
}

#portfolio {
  .portfolio-item {
    margin: 0 0 15px;
    right: 0;
    .portfolio-link {
      cursor: pointer;
      display: block;
      position: relative;
      margin: 0 auto;
      .caption {
        background: fade($theme-success, 90%);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        &:hover {
          opacity: 1;
        }
        .caption-content {
          position: absolute;
          width: 100%;
          height: 20px;
          font-size: 20px;
          text-align: center;
          top: 50%;
          margin-top: -12px;
          color: white;
          i {
            margin-top: -12px;
          }
          h3,
          h4 {
            margin: 0;
          }
        }
      }
    }
  }
  * {
    z-index: 2;
  }
}

@media (min-width: 767px) {
  #portfolio {
    .portfolio-item {
      margin: 0 0 30px;
    }
  }
}

.floating-label-form-group {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid $gray-lighter;
  input,
  textarea {
    z-index: 1;
    position: relative;
    padding-right: 0;
    padding-left: 0;
    border: none;
    border-radius: 0;
    font-size: 1.5em;
    background: none;
    box-shadow: none !important;
    resize: none;
  }
  label {
    display: block;
    z-index: 0;
    position: relative;
    top: 2em;
    margin: 0;
    font-size: 0.85em;
    line-height: 1.764705882em;
    vertical-align: middle;
    vertical-align: baseline;
    opacity: 0;
    -webkit-transition: top 0.3s ease, opacity 0.3s ease;
    -moz-transition: top 0.3s ease, opacity 0.3s ease;
    -ms-transition: top 0.3s ease, opacity 0.3s ease;
    transition: top 0.3s ease, opacity 0.3s ease;
  }
  &:not(:first-child) {
    padding-left: 14px;
    border-left: 1px solid $gray-lighter;
  }
}

.floating-label-form-group-with-value {
  label {
    top: 0;
    opacity: 1;
  }
}

.floating-label-form-group-with-focus {
  label {
    color: $theme-success;
  }
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid $gray-lighter;
}

footer {
  color: white;
  h3 {
    margin-bottom: 30px;
  }
  .footer-above {
    padding-top: 50px;
    @media(max-width: 992px) {
      padding: 30px 10px;
    }
    background-color: $theme-primary;
  }
  .footer-below {
    padding: 25px 0;
    text-align: center;
    background-color: darken($theme-primary, 5%);
  }
}

// Buttons

.btn-outline {
  color: white;
  font-size: 20px;
  border: solid 2px white;
  background: transparent;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $theme-success;
    background: white;
    border: solid 2px white;
  }
}

.btn-primary {
  @include button-variant(white, $theme-primary, $theme-primary);
  font-weight: 700;
}

.btn-success {
  @include button-variant(white, $theme-success, $theme-success);
  font-weight: 700;
}

.btn-social {
  display: inline-block;
  height: 50px;
  width: 50px;
  border: 2px solid white;
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 45px;
}

.scroll-top {
  position: fixed;
  right: 2%;
  bottom: 2%;
  width: 50px;
  height: 50px;
  z-index: 1049;
  .btn {
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 28px;
  }
}

.portfolio-modal {
  .modal-content {
    border-radius: 0;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    min-height: 100%;
    padding: 100px 0;
    text-align: center;
    h2 {
      margin: 0;
      font-size: 3em;
    }
    img {
      margin-bottom: 30px;
    }
    .item-details {
      margin: 30px 0;
    }
  }
  .close-modal {
    position: absolute;
    width: 75px;
    height: 75px;
    background-color: transparent;
    top: 25px;
    right: 25px;
    cursor: pointer;
    &:hover {
      opacity: 0.3;
    }
    .lr {
      height: 75px;
      width: 1px;
      margin-left: 35px;
      background-color: $theme-primary;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      /* IE 9 */
      -webkit-transform: rotate(45deg);
      /* Safari and Chrome */
      z-index: 1051;
      .rl {
        height: 75px;
        width: 1px;
        background-color: $theme-primary;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Safari and Chrome */
        z-index: 1052;
      }
    }
  }
  .modal-backdrop {
    opacity: 0;
    display: none;
  }
}

div#maincontent {
  outline: none;
}

@media (max-width: 1100px) {
  #modalTimechart #content {
    max-width: 90%;
    overflow-x: hidden;
  }
  .chartContainer {
    border-radius: 0px;
  }
  #modalTimechart .box {
    display: none;
  }
}

.modal div.iframe-container {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  max-width: 600px;
  margin: 15px auto;
}
.modal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section:nth-child(odd) {
  background: $background1;
}

section {
  color: $color2;
}

.modal {
  overflow: auto !important;
}

.hidden-mobile {
  @media(max-width: 992px) {
    display: none;
  }
}
