section.header {
  &.background {
    background-image: url("/img/blue17.jpg");
    .webp & {
      background-image: url("/img/blue17.webp");
    }
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & .flatButton {
      background: white;
      color: #2c3e50;
    }
  }
  & img {
    max-width: 250px;
  }

  & iframe {
    max-width: 90%;

    width: 853px;
    height: 505px;
    @media (max-width: 1300px) {
      width: 560px;
      height: 315px;
    }
  }

  & .flatButton {
    background: #2c3e50;
    color: white;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 15px;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    border: 0;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
  }

  & .container {
    display: flex;
    height: 100vh;
    padding-top: 0;
    @media (min-width: 768px) {
      padding-top: 120px !important;
    }
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    align-items: center;
    & .page-scroll {
      font-size: 20px;
      margin-top: 30px;
    }
  }
}
.flatButton {
  cursor: pointer;
}
