// Variables

// Gray and Brand Colors for use across theme

$theme-primary:    #2C3E50;
$theme-success:    #EEE;
$background1: rgb(219, 222, 225);
$background2: #fff;
$color2: #2C3E50;
$color2OnBlue: fade(#fff, 0.83);

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee
