#footer {
  //border-top: 1px solid white;
  & a {
    color: lightblue;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    justify-content: space-evenly;
    .footer-col {
      margin-bottom: 50px;
      @media(max-width: 992px) {
        flex-direction: column;
        margin-bottom: 0;
        & .footer-col:last-child {
          margin-bottom: 20px;
        }
      }
    }
    @media(max-width: 992px) {
      flex-direction: column;
    }
  }
}
