@import "../../scss/variables.scss";

div.separator {
  padding: 0 0 2em 0;
  border: none;
  border-top: solid 5px;
  text-align: center;
  max-width: 250px;
  margin: 25px auto 30px;
  @media(max-width: 992px) {
    margin: 25px auto 6px;
  }

  & i:after {
    font-style: normal;
    content: "\f005";
    font-family: FontAwesome;
    display: inline-block;
    position: relative;
    top: -0.8em;
    font-size: 2em;
    padding: 0 0.25em;
  }
  &.white i:after {
    background-color: white;
  }
  &.grey i:after {
    background-color: #dbdee1;
  }
  & i:after {
    color: #2C3E50;
  }
}
