@import "../../scss/variables";

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container {
  display: flex;
  margin: auto;
  align-content: space-between;
  justify-content: space-between;
  font-size: 14px;
}

.navbar-custom {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: $theme-primary;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
  & .navbar-brand {
    color: white;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
    }
  }
  transition: all 1s;
  & .navbar-nav {
    letter-spacing: 1px;
    & li {
      list-style: none;
      display: inline-block;
      & a {
        border-radius: 0.5em;
        text-decoration: none;
        padding: 10px 15px;
      }
      & a {
        color: white;
        &:focus,
        &active {
          color: white;
        }
        &:hover {
          color: lightblue;
        }
      }
      &.active {
        & a {
          color: $theme-primary;
          background: $theme-success;
          &:hover,
          &:focus,
          &:active {
            color: $theme-primary;
            background: white;
          }
        }
      }
    }
  }
  & .navbar-toggle {
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    border-color: white;
    &:hover,
    &:focus {
      background-color: $theme-success;
      color: white;
      border-color: $theme-success;
    }
  }
}

@media (min-width: 768px) {
  .navbar-custom {
    padding: 25px 0;
    -webkit-transition: padding 0.3s;
    -moz-transition: padding 0.3s;
    transition: padding 0.3s;
    & .navbar-brand {
      font-size: 2em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
}

@media (max-width: 790px) {
  .navbar-custom {
  }
}

#skipnav a {
  padding: 6px;
  position: absolute;
  top: -40px;
  left: 0px;
  color: white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  background: transparent;
  transition: top 1s ease-out, background 1s linear;
  z-index: 2000;
  &:focus {
    position: absolute;
    left: 0px;
    top: 0px;
    background: $theme-success;
    outline: 0;
    transition: top 0.1s ease-in, background 0.5s linear;
  }
}

.navbar-header {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.isScrolledDown {
  background: rgba(44, 62, 80, 1) !important;
  padding: 10px 0;
  transition: all 0.5s;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  @media (min-width: 790px) {
    & .navbar-brand {
      font-size: 1.5em;
    }
  }
}
.isOnTop {
  border-bottom: 1px solid skyblue;
  background: rgba(44, 62, 80, 0.6) !important;
  @media(max-width: 800px) {
    background: rgba(44, 62, 80, 1) !important;
  }
  padding: 25px 0;
  transition: all 0.5s;
}

.navbar-header {
  & button.navbar-toggle {
    display: none;
    background: rgba(44, 62, 80, 0.1) !important;
    border: 1px solid white;
    padding: 10px;
    font-size: 14px;
    outline: 0;
  }
}

@media (max-width: 768px) {
  #mainNav {
    padding: 0;
  }
  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .navbar-header {
    padding: 10px 20px;
    justify-content: space-between;
    & button.navbar-toggle {
      display: block;
    }
  }
  #navbar-collapsable {
    border-top: 1px solid skyblue;
    transition: height 0.3s;
    height: 170px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #navbar-collapsable.collapsed {
    border-top: 0;
    height: 0;
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    li {
      width: 100%;
      a {
        box-sizing: border-box;
        width: 100%;
        display: block;
        text-align: center;
      }
    }
  }
  .navbar-brand {
    font-size: 18px;
  }
}
